<template>
  <div id="login-page">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- login container -->
        <b-card class="mb-0">
          <center>
            <img
              src="../../assets/images/logo/logo_scrapper.png"
              width="100"
              class="mx-auto"
            />
          </center>
          <b-link class="brand-logo">
            <h2 class="brand-text text-info ml-1 text-center">
              <p class="h4 text-info">MONSCRAP</p>
              <p>Monitoring Scraper</p>
            </h2>
          </b-link>
          <hr />
          <b-link class="brand-logo">
            <h2 class="brand-text text-info ml-1">Login</h2>
          </b-link>
          <!-- alert wrong username or password -->
          <b-alert
            variant="danger"
            :show="closeAlertTime"
            @dismissed="closeAlertTime = 0"
            class="px-1"
            style="padding-top: 5px; padding-bottom: 5px"
          >
            <p><strong>Login Gagal!</strong></p>
            <small>Username atau Password Salah. Silahkan Coba Lagi</small>
          </b-alert>

          <!-- login form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email field -->
              <b-form-group label-for="username" label="Username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    name="login-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Username"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password field -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- remember me button -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  button-variant="info"
                  v-model="status"
                  name="remember_me"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- login button -->
              <b-button variant="info" type="submit" block>
                Login
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
      <loader-component v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BAlert,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import loader from "@/views/components/floatingLoader.vue";

export default {
  name: "Login",
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    "loader-component": loader,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      password: "",
      status: "",
      closeAlertTime: 0,
      isLoading: false,
      // form validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const formData = new FormData();
          formData.append("username", this.username);
          formData.append("password", this.password);
          useJwt
            .login(formData)
            .then((response) => {
              const { userData } = response.data;
              useJwt.setToken(response.data.access_token);
              useJwt.setRefreshToken(response.data.refresh_token);
              useJwt.setUserData(response.data.userData);
              this.$store.commit("data/SET_USER", userData);
              this.$store.commit("data/SET_TOKEN", response.data.access_token);
              this.$ability.update(userData.ability);
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Hallo ${userData.fullName || userData.username}`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Login berhasil dilakukan.`,
                    },
                  });
                });
            })
            .catch(() => {
              this.closeAlertTime = 10;
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>
<!-- scss styling -->
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<!-- css styling -->
<style scoped>
.auth-wrapper {
  background-color: #7252d3;
  background: url("../../assets/images/pages/background.png");
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
input:focus {
  border-color: #00cfe8 !important;
}
.input-group:focus-within .input-group-text {
  border-color: #00cfe8 !important;
}
.custom-control-input ~ .custom-control-label::before {
  color: #fff;
  border-color: #00cfe8 !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}
</style>
